import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CustomBreadcrumb from "../../components/breadcrumb";
import AttendanceTable from "../leaves/AttendanceTable";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

let AttendanceReport = (props) => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>HR Attendance Report </h1>

      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "Attendance Report", link: "/attendance-report" },
          ]}
        />
      </div>
      <div className="tabContainer leaveTab">
        {/* tabs */}
        <Tabs
          value={value}
          onChange={handleChange}
          component="div"
          className="tabList tabPaneTable"
        >
          {/* <Tab label="Summary" wrapped {...a11yProps(0)} /> */}
          <Tab label="Details" {...a11yProps(0)} />
        </Tabs>
        {/* <TabPanel value={value} index={0} component="div" className="tabTable">
          <LeaveDetails />
        </TabPanel> */}
        <TabPanel value={value} index={0} component="div" className="tabTable">
          <AttendanceTable />
        </TabPanel>
      </div>
    </div>
  );
};
export default AttendanceReport;
