import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../assets/images/np-logo.svg";
import UserPic from "./../assets/images/avatarPic.png";
import { Base_URL } from "./../constants";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { LayoutStyles } from "./layout.style";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

const HeaderComponent = props => {
  const { open, handleDrawerOpen, handleDrawerClose } = props;
  const classes = LayoutStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropdown = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let employeeHeaderPic =
    props.user && props.user.avatar
      ? `${Base_URL}${props.user.avatar}`
      : UserPic;
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}
    >
      <Toolbar>
        {!open ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={
              (clsx({
                [classes.hide]: open
              }),
              classes.menuButton)
            }
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
            onClick={handleDrawerClose}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography variant="h6" noWrap className="logoContainer">
          <img src={Logo} alt="Logo" className="dashboardLogo" />
        </Typography>
        <div>
          {/* <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          /> */}

          <IconButton aria-label="show 17 new notifications">
            {/* <Badge badgeContent={17} color="secondary">
              
            </Badge> */}
            <NotificationsNoneIcon />
          </IconButton>

          <span className="userName">
            Hi,{" "}
            {props.user && props.user.name ? props.user.name.split(" ")[0] : ""}
          </span>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className="userIcon"
          >
            <img src={employeeHeaderPic} alt="User Pic" />
          </IconButton>

          <Menu
            className="userAccount-dropdown"
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={openDropdown}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem>
              <RouterLink
                to={`/employees/${props.user._id}/view`}
                className="routerLink"
              >
                Profile
              </RouterLink>
            </MenuItem>
            <MenuItem onClick={props.logoutUser}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});
const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser())
});
export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
