import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SuccessImg from "./../../assets/images/Successful.gif";
import Logo from "./../../assets/images/np-logo.svg";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

let PunchMessage = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  return (
    <div className='successModal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className='text-center'>
              <img src={Logo} alt="success_gif" />
            </h2>
            <div className='text-center'>
              <img src={SuccessImg} alt="success_gif" />
              <p>Your punching has been done successfully.</p>

            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default PunchMessage;