import * as yup from "yup";
import Axios from "axios";

let addEmployeeValidationSchema = {};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const FILE_SIZE = 2;

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];

addEmployeeValidationSchema.personal = yup.object().shape({
  employeeCode: yup
    .string()
    .required()
    .test(
      "Id-available",
      "This employee ID is already taken",
      async function(employeeCode) {
        let that = this;
        try {
          let queryString = `/users/view/check-employee-code-availability/${employeeCode}`;
          if (that.parent.userProfileId) {
            queryString += `?profileId=${that.parent.userProfileId}`;
          }
          let { data } = await Axios.get(queryString);
          return data.isAvailable;
        } catch (err) {
          return false;
        }
      }
    ),
  user: yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-z A-Z]+$/,
        "Name cannot have numbers or special characters."
      )
      .required("User name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email is not valid")
      .matches(
        /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(npglobal.in|netprophetsglobal.com)$/g,
        "Email should belong to organisation"
      )
      .test("email-available", "This email is already taken", async function(
        email
      ) {
        let that = this;
        try {
          let queryString = `/users/view/check-email-availability/${email}`;
          if (that.parent._id) {
            queryString += `?userId=${that.parent._id}`;
          }
          let { data } = await Axios.get(queryString);
          return data.isAvailable;
        } catch (err) {
          return false;
        }
      })
  }),
  contactNumber: yup
    .string()
    .required("Mobile number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  personalEmail: yup
    .string()
    .email("Enter a valid email")
    .nullable(),
  relationPerson: yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-z A-Z.]+$/,
        "Name cannot have numbers or special characters."
      )
      .nullable(),
    relation: yup
      .string()
      .oneOf(["Father", "Spouse", "Mother"], "Realtion name is required")
      .nullable()
  }),
  epfNumber: yup.string("Enter EPF A/C Number").nullable(),
  uan: yup
    .number()
    .typeError("Please enter number only")
    .nullable(),
  aadhar: yup
    .number()
    .typeError("Please enter number only")
    .nullable(),
  emergency: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .typeError("Please enter character only")
        .nullable()
        .matches(
          /^[a-z A-Z.]+$/g,
          "Name cannot have numbers or special characters."
        ),
      contact: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .nullable()
    })
  )
});

addEmployeeValidationSchema.basic = yup.object().shape({
  presentAddress: yup.object().shape({
    mobile: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .nullable()
  }),
  permanentAddress: yup.object().shape({
    mobile: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .nullable()
  })
});

addEmployeeValidationSchema.medical = yup.object().shape({
  medical: yup.object().shape({
    insuranceProvider: yup.string().required("Insurance provider is required"),
    sumAssured: yup.string().required("Enter sum assured")
  })
});

addEmployeeValidationSchema.educations = yup.object().shape({
  educations: yup.array().of(
    yup.object().shape({
      degree: yup.string().required("Degree is required"),
      subject: yup.string().required("Subject is required"),
      passingYear: yup
        .number()
        .typeError("Invalid year format")
        .required("Passing year is required"),
      institute: yup.string().required("Institute is required")
    })
  )
});

addEmployeeValidationSchema.trainings = yup.object().shape({
  trainings: yup.array().of(
    yup.object().shape({
      program_name: yup.string().required("Program name is required"),
      year: yup
        .number()
        .typeError("Invalid year format")
        .required("Year is required"),
      duration: yup
        .number()
        .typeError("Invalid duration")
        .required("Duration is required")
    })
  )
});

addEmployeeValidationSchema.skills = yup.object().shape({
  skills: yup.array().of(
    yup.object().shape({
      skill: yup.string().required("Skill is required"),
      year_of_exp: yup
        .number()
        .typeError("Enter only number")
        .required("Year of experience is required"),
      competency_level: yup
        .number()
        .typeError("Enter your level between 1-10")
        .lessThan(11, "Enter your level between 1-10")
        .moreThan(0, "Enter your level between 1-10")
        .required("Competency level is required")
    })
  )
});

addEmployeeValidationSchema.certificates = yup.object().shape({
  certificates: yup.array().of(
    yup.object().shape({
      certificate_name: yup.string().required("Certificate name is required"),
      year_of_certification: yup
        .number()
        .typeError("Invalid year format")
        .required("Year is required"),
      institute_name: yup.string().required("Institute name is required")
    })
  )
});

addEmployeeValidationSchema.jobHistories = yup.object().shape({
  jobHistories: yup.array().of(
    yup.object().shape({
      organization_name: yup.string().required("Organization name is required"),
      last_position: yup.string().required("Last position is required"),
      HRName: yup
        .string()
        .matches(
          /^[a-z A-Z.]+$/,
          "Name cannot have numbers or special characters."
        )
        .nullable(),
      HRContact: yup
        .number()
        .typeError("Invalid landline number.Enter number only")
        .nullable(),
      HREmail: yup
        .string()
        .email("Enter a valid email")
        .nullable(),
      ReportingPersonName: yup
        .string()
        .matches(
          /^[a-z A-Z.]+$/,
          "Name cannot have numbers or special characters."
        )
        .nullable(),
      ReportingPersonEmail: yup
        .string()
        .email("Enter a valid email")
        .nullable(),
      ReportingPersonContact: yup
        .number()
        .typeError("Invalid contact number.Enter number only")
        .nullable(),
      ReportingPersonDesignation: yup
        .string()
        .matches(
          /^[a-z A-Z.]+$/,
          "Designation cannot have numbers or special characters."
        )
        .nullable()
    })
  )
});

addEmployeeValidationSchema.documents = yup.object().shape({
  documents: yup.array().of(
    yup.object().shape({
      _id: yup.string(),
      documentTitle: yup.string().required("Document name is required"),
      fileName: yup.mixed().when("_id", {
        is: undefined, //just an e.g. you can return a function
        then: yup
          .mixed()
          .required("Upload the file")
          .test("file-size", "You can upload max 5mb file", value => {
            if (!value) {
              return false;
            }
            return value.size / 1024 / 1024 <= FILE_SIZE;
          })
          .test("fileType", "Unsupported File Format", value => {
            if (!value) {
              return false;
            }
            return SUPPORTED_FORMATS.includes(value.type);
          })
        // otherwise: Yup.mixed()
      })
    })
  )
});

export default addEmployeeValidationSchema;
