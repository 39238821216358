import "date-fns";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumb from "../../components/breadcrumb";
import Attendance from "../leaves/Attendance";
import LeaveHistory from "../leaves/LeaveHistory";
import AttendanceHistory from "../leaves/AttendanceHistory";
import LeaveData from "../leaves/Leave";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Popover,
} from "@material-ui/core";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import LeftArrow from "./../../assets/images/arrow_left.svg";
import RightArrow from "./../../assets/images/arrow_right.svg";
import Axios from "axios";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ForwardSharpIcon from "@material-ui/icons/ForwardSharp";
import moment from "moment";
import TriggerNotification from "./TriggerNotification";
import Calender from "./Calender";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${"index"}`,
  };
}

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const LeaveDashboard = (props) => {
  const [value, setValue] = React.useState(0);
  const [leaveInfo, setLeaveInfo] = React.useState({});
  const [cofData, setCofData] = React.useState(0);
  const [punchValue, setPunchValue] = React.useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [currentDateData, setCurrentDateData] = useState({});
  //creating IP state
  const [ip, setIP] = useState("");
  // current date
  const current = new Date();
  const [date, setDate] = useState(current);
  const [punchType, setpunchType] = useState("");
  const [leavesData, setLeavesData] = useState({
    availableLeaves: [],
  });
  const [appliedLeaves, setAppliedLeaves] = useState([]);
  const [employeesDetails, setEmployeesDetails] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let [deleteStatus, setDeleteStatus] = useState(false);
  let [alert, setAlert] = useState({
    open: false,
    message: "",
    duration: 3000,
    color: "",
  });

  const formatDateYearwise = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };
  const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
  };

  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getAttendanceData();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // punching radio form
  const handleRadioChange = (event) => {
    setPunchValue(event.target.value);
    // console.log(event.target.value);
  };
  // clock on change
  const [selectedDate, setSelectedDate] = React.useState();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  async function getEmployeesDetails() {
    try {
      let { data } = await Axios.get(`/users/view`);
      setEmployeesDetails(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getAttendanceData() {
    const values = {
      scope: "my",
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
    };
    try {
      let { data } = await Axios.post("/api/leaves/all/timedata", values);
      setAttendanceData(data?.data[0] ? data?.data[0] : {});
    } catch (err) {
      console.log(err);
    }
  }
  async function getCurrentDateData() {
    const values = {
      scope: "my",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
    try {
      let { data } = await Axios.post("/api/leaves/all/timedata", values);
      setCurrentDateData(data?.data.length > 0 ? data?.data[0] : {});
    } catch (err) {
      console.log(err);
    }
  }
  const alertMsg = (msg) => {
    setAlert({
      ...alert,
      message: msg,
      color: "success",
      open: true,
    });
  };

  const getData = async () => {
    const res = await Axios.get("https://api.ipify.org?format=json");
    setIP(res.data.ip);
    // console.log(res.data);
  };

  let totalOD = 0;
  let totalWFH = 0;
  let totalLWP = 0;

  // get self applied leaves
  async function getAppliedLeavesData(filterParams = {}) {
    try {
      filterParams["scope"] = "my";
      let { data } = await Axios.post("/api/leaves/all/leaves", filterParams);
      setAppliedLeaves(data.data);
    } catch (err) {
      console.log(err);
    }
  }
  appliedLeaves.forEach((leave) => {
    if (leave?.status === "Approved") {
      if (leave?.leaveType?.title === "OD") totalOD++;
      if (leave?.leaveType?.title === "WFH") totalWFH++;
      if (leave?.leaveType?.title === "LWP") totalLWP++;

    }
  });

  // punching api
  async function punchingData(punc_type) {
    // let punch_time = CustomDateFormat({ utc_date: selectedDate, isDay: false, isTime: true });
    let punch_time = moment.utc(selectedDate).local().format("h:mm A");
    await Axios.get(
      `/crons/attendance/update-punch-record?employeeCode=${props.user.employeeCode
      }&startDate=${formatDateYearwise(
        date
      )}&punchType=${punc_type}&punchTime=${punch_time}&ip=${ip}`
    )
      .then((res) => {
        let msg = res.data.message;
        alertMsg(msg);
      })
      .catch((err) => {
        var msg = "Something went wrong.";
        alertMsg(msg);
      });
    setAnchorEl(null);
    setAttendanceData({});
  }
  // leave Info
  async function getLeaveInfo() {
    try {
      let { data } = await Axios.post("/api/leaves/leaveledger");
      setLeaveInfo(data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  }
  // cof data
  async function getCofData() {
    try {
      let { data } = await Axios.get("/api/leaves/cof-leave");
      setCofData(data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  }

  async function getLeavesData() {
    try {
      let { data } = await Axios.get(
        `/api/leaves/apply/info/employee/${props.user.employeeCode}`
      );
      setLeavesData(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(function () {
    getAppliedLeavesData();
    // getLeavesData();
    getCurrentDateData();
    getData();
    getLeaveInfo();
    getCofData();
    getEmployeesDetails();
  }, []);

  function deleteLeave(leaveId) {
    if (window.confirm("Do you want to delete this leave?")) {
      Axios.delete(`/api/leaves/delete/${leaveId}`).then(function (res) {
        setAlert({
          ...alert,
          message: res.data.message,
          color: "success",
          open: true,
        });
        getAppliedLeavesData();
      });
    }
  }

  function handleAppliedLeaveFilterSubmit(params) {
    let filterParams = params;
    getAppliedLeavesData(filterParams);
  }

  // date handler
  const dateHandler = (date, symbol) => {
    if (symbol === "+") {
      if (formatDate(date) === formatDate(current)) {
        var msg = "Punching cannot be done for future dates.";
        alertMsg(msg);
        return false;
      }
      setDate(new Date(date.getTime() + 24 * 60 * 60 * 1000));
      return false;
    }
    if (
      formatDate(date) ===
      formatDate(new Date(current.getTime() - 24 * 60 * 60 * 1000))
    ) {
      var msg = "Punching cannot be done for more than two days.";
      alertMsg(msg);
      return false;
    }
    setDate(new Date(date.getTime() - 24 * 60 * 60 * 1000));
    return false;
  };
  //
  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>Leave Dashboard</h1>
      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "Leave", link: "/leave/dashboard" },
          ]}
        />
      </div>
      <Grid container spacing={2} className="mB15">
        <Grid item xs={12} display>
          <Grid container spacing={2} className="mB15">

            <Grid item xs={2}>
              <Paper className="employeeDetailsCard leaveCard box5">
                <Typography component="div" className="w-100">
                  <Typography
                    variant="h6"
                    component="h5"
                    className="mB0 card-head"
                  >
                    Attendance
                  </Typography>
                  {!(Object.keys(leaveInfo).length > 0 && (leaveInfo?.onsite === true)) ?
                    <div className="attendSec">
                      {Object.keys(currentDateData).length !== 0 ? (
                        <>
                          <p className="head">(Today's Attendance)</p>
                          <div className="para">
                            <div className="leftSec">
                              <Typography component="p">Punch In :</Typography>
                              <Typography component="p">Punch Out :</Typography>
                            </div>
                            <div className="rightSec">
                              <Typography component="p">{currentDateData?.in1 ? moment
                                .utc(currentDateData?.in1)
                                .format("h:mm a") : 'N/A'}</Typography>
                              <Typography component="p">
                                {currentDateData?.out1 ? (currentDateData?.out1 === 'missCurrent' ? 'n/a' : moment
                                .utc(currentDateData?.out1)
                                .format("h:mm a"))  : 'N/A'}
                                </Typography>
                            </div>
                          </div>
                        </>
                      ) : ('')
                      }

                    </div>
                    : (
                      <Typography component="div" className="date-sec mT8">
                        <Button
                          color="primary"
                          variant="contained"
                          className="punching-btn w-100"
                          onClick={handleClick}
                        >
                          Punch Attendance
                        </Button>
                      </Typography>
                    )
                  }

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <form noValidate>
                      <Typography component="div" className="punchTime">
                        {Object.keys(attendanceData).length === 0 ? (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change time",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        ) : !(attendanceData?.in1 && attendanceData?.out1) ? (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change time",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        ) : null}
                        <RadioGroup
                          aria-label="punchType"
                          name="punchType"
                          value={punchValue}
                          onChange={handleRadioChange}
                        >
                          {Object.keys(attendanceData).length === 0 ? (
                            <FormControlLabel
                              value="in"
                              control={<Radio />}
                              label="Punch In"
                            />
                          ) : attendanceData?.in1 ? (
                            <Typography component="p" className="dateSec">
                              <span className="puchTxt">Punch In at :</span>
                              <br />{" "}
                              <span className="bold">
                                {moment
                                  .utc(attendanceData?.in1)
                                  .format("h:mm a DD-MM-YYYY")}
                              </span>
                            </Typography>
                          ) : (
                            <FormControlLabel
                              value="in"
                              control={<Radio />}
                              label="Punch In"
                            />
                          )}

                          {Object.keys(attendanceData).length !== 0 &&
                            (attendanceData?.out1 && (attendanceData?.out1 !== 'missCurrent') ? (
                              <Typography component="p" className="dateSec">
                                <span className="puchTxt">Punch Out at</span>{" "}
                                <span className="bold">
                                  {moment
                                    .utc(attendanceData?.out1)
                                    .format("h:mm a DD-MM-YYYY")}
                                </span>
                              </Typography>
                            ) : (
                              attendanceData?.in1 && (
                                <FormControlLabel
                                  value="out"
                                  control={<Radio />}
                                  label="Punch Out"
                                />
                              )
                            ))}
                        </RadioGroup>
                        {Object.keys(attendanceData).length === 0 ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              punchingData(punchValue);
                            }}
                          >
                            Submit
                          </Button>
                        ) : !(attendanceData?.in1 && attendanceData?.out1) ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              punchingData(punchValue);
                            }}
                          >
                            Submit
                          </Button>
                        ) : 
                        (attendanceData?.in1 && attendanceData?.out1 === "missCurrent") ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              punchingData(punchValue);
                            }}
                          >
                            Submit
                          </Button>
                        ) :
                        (
                          attendanceData?.out1 &&
                          attendanceData?.in1 && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleClose}
                            >
                              Close
                            </Button>
                          )
                        )}
                      </Typography>
                    </form>
                  </Popover>
                  {!(Object.keys(leaveInfo).length > 0 && (leaveInfo?.onsite === true)) ?
                    ('') :
                    (<Typography component="div" className="date-sec mT8">
                      <Button
                        variant=""
                        className="arrow-btn"
                        onClick={() => dateHandler(new Date(date), "-")}
                        disabled={
                          formatDate(date) ===
                          formatDate(
                            new Date(current.getTime() - 24 * 60 * 60 * 1000)
                          )
                        }
                      >
                        <img src={LeftArrow} alt="Left_Arrow" />
                      </Button>

                      <Typography variant="h6" component="p">
                        {formatDate(date)}
                      </Typography>
                      <Button
                        variant=""
                        className="arrow-btn"
                        onClick={() => dateHandler(new Date(date), "+")}
                        disabled={date === current}
                      >
                        <img src={RightArrow} alt="Right_Arrow" />
                      </Button>
                    </Typography>)

                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className="employeeDetailsCard leaveCard box4">
                <Typography component="div" className="w-100">
                  <Typography
                    variant="h6"
                    component="h5"
                    className="mB0 card-head"
                  >
                    PL
                  </Typography>
                  <Typography variant="h6" component="p">
                    (Planned Leaves)
                  </Typography>

                  <div className="pleave">
                    <div className="leftSec">
                      <Typography component="p">Total Leave :</Typography>
                      <Typography component="p">Taken :</Typography>
                      <Typography component="p">Balance :</Typography>
                    </div>
                    <div className="rightSec">
                      {Object.keys(leaveInfo).length > 0 && (
                        <>
                          <Typography component="p">
                            {leaveInfo?.PL?.total}
                          </Typography>
                          <Typography component="p">
                            {leaveInfo?.PL?.taken}
                          </Typography>
                          <Typography component="p">
                            {leaveInfo?.PL?.balance}
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                  <Typography component="div">
                    <Button
                      color="primary"
                      className="leaveBtn"
                      component={RouterLink}
                      to="/deduction"
                    >
                      View Details
                    </Button>
                  </Typography>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className="employeeDetailsCard leaveCard box3">
                <Typography component="div" className="w-100">
                  <Typography
                    variant="h6"
                    component="h5"
                    className="mB0 card-head"
                  >
                    CoF
                  </Typography>
                  <Typography variant="h6" component="p">
                    (Complimentary Off)
                  </Typography>
                  <div className="pleave">
                    <div className="leftSec">
                      <Typography component="p">Total Comp-off :</Typography>
                      <Typography component="p">Taken :</Typography>
                      <Typography component="p">Balance :</Typography>
                    </div>
                    <div className="rightSec">
                      {Object.keys(cofData).length > 0 && (
                        <>
                          <Typography component="p">
                            {cofData?.totalCof}
                          </Typography>
                          <Typography component="p">
                            {cofData?.takenCof}
                          </Typography>
                          <Typography component="p">
                            {(cofData?.totalCof) - (cofData?.takenCof)}
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className="employeeDetailsCard leaveCard box2">
                <Typography component="div" className="w-100">
                  <Typography
                    variant="h6"
                    component="h5"
                    className="mB0 card-head"
                  >
                    RH
                  </Typography>
                  <Typography variant="h6" component="p">
                    (Restricted Holidays)
                  </Typography>
                  <div className="pleave">
                    <div className="leftSec">
                      <Typography component="p">Total RH :</Typography>
                      <Typography component="p">Taken :</Typography>
                      <Typography component="p">Balance :</Typography>
                    </div>
                    <div className="rightSec">
                      {Object.keys(leaveInfo).length > 0 && (
                        <>
                          <Typography component="p">
                            {leaveInfo?.RH?.total}
                          </Typography>
                          <Typography component="p">
                            {leaveInfo?.RH?.taken}
                          </Typography>
                          <Typography component="p">
                            {leaveInfo?.RH?.balance}
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className="employeeDetailsCard leaveCard  box1">
                <Typography component="div" className="w-100">
                  <Typography
                    variant="h6"
                    component="h5"
                    className="mB0 card-head"
                  >
                    OL
                  </Typography>
                  <Typography variant="h6" component="p">
                    (Other Leaves)
                  </Typography>
                  <div className="pleave">
                    <div className="leftSec">
                      <Typography component="p">OD Taken :</Typography>
                      <Typography component="p">WFH Taken :</Typography>
                      <Typography component="p">LWP Taken :</Typography>
                    </div>
                    <div className="rightSec">
                      {Object.keys(appliedLeaves).length > 0 && (
                        <>
                          <Typography component="p">
                            {totalOD}
                          </Typography>
                          <Typography component="p">
                            {totalWFH}
                          </Typography>
                          <Typography component="p">
                            {totalLWP}
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} display>
          <Grid container spacing={2} className="mB15 ctm">
            <Grid item xs={5}>
              <TriggerNotification />
            </Grid>
            <Grid item xs={7} display>
              <Calender />

            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mB15">
        <Grid item xs={12}>
          <div className="btnConatinerR">
            <Button
              color="primary"
              variant="contained"
              className="routerLink-btn"
              component={RouterLink}
              to="/leave/apply"
            >
              APPLY
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="routerLink-btn mL10"
              component={RouterLink}
              to="/leave/status"
            >
              APPROVE
            </Button>
          </div>
        </Grid>
      </Grid>

      <div className="tabContainer leaveTab">
        <Tabs
          value={value}
          onChange={handleChange}
          component="div"
          className="tabList tabPaneTable"
        >
          <Tab label="Leave" wrapped {...a11yProps(0)} />
          <Tab label="Attendance" {...a11yProps(1)} />

          <Tab
            label="Attendance History"
            {...a11yProps(2)}
            className="floatR"
          />
          <Tab label="Leave History" {...a11yProps(3)} className="floatR" />
          <Tab
            label={
              <span>
                My Team{" "}
                <ForwardSharpIcon style={{ "vertical-align": "middle" }} />
              </span>
            }
            {...a11yProps(4)}
            disabled
            className="floatR"
          />
        </Tabs>
        <TabPanel value={value} index={0} component="div" className="tabTable">
          <LeaveData />
        </TabPanel>
        <TabPanel value={value} index={1} component="div" className="tabTable">
          <Attendance />
        </TabPanel>

        <TabPanel value={value} index={2} component="div" className="tabTable">
          {/* <Attendance isHistory={true} /> */}
          <AttendanceHistory />
        </TabPanel>
        <TabPanel value={value} index={3} component="div" className="tabTable">
          <LeaveHistory />
        </TabPanel>
        <TabPanel value={value} index={4} component="div" className="tabTable">
          jhnjbh
        </TabPanel>
      </div>
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={(e) => {
          setAlert({
            ...alert,
            open: false,
            class: "",
            message: "",
          });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        message={alert.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(e) => {
                setAlert({
                  ...alert,
                  open: false,
                  class: "",
                  message: "",
                });
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(LeaveDashboard);
