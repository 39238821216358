import React, { useState, useEffect } from "react";
import Marquee from "react-marquee-master";
import Axios from "axios";

let TriggerNotification = () => {

    const [triggerNotification, setTriggerNotification] = useState([]);
    async function getTriggerNotification() {
        try {
            let { data } = await Axios.get("/crons/attendance/alert-notifications");
            //   console.log(data);
            setTriggerNotification(data);
            setTimeout(() => {
                getTriggerNotification()
            }, 50000)

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(function () {
        getTriggerNotification();
    }, []);

    // console.log(triggerNotification);


    // let tableData = [];
    // tableData = triggerNotification.map(data => ({
    //     empCode: data?.hourWeekelyNotifications,
    // }));

    return (
        <div className="tigger-sec">
            <div className="tigger-head">
                <h4>Notifications</h4>
            </div>
            <div className="tigger-notification">
                <div className="tigger-card">
                    {Object.keys(triggerNotification).length > 0 &&
                        <>
                            <Marquee
                                delay={"60"}
                                marqueeItems={triggerNotification.map((data) =>
                                    <p>
                                        <span>{data}</span>
                                    </p>
                                )}
                            />

                            {/* <p>loffjafcjachfhjcfchfahcfhjcsfjhcfashcfhj xfjhh</p> */}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default TriggerNotification;
