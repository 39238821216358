import React, { useState, useEffect } from "react";
import applyLeaveValidationSchema from "../../validation/employees/applyLeaveValidation";
import { Link as RouterLink } from "react-router-dom";
import { withFormik, getIn, ErrorMessage } from "formik";
import CustomBreadcrumb from "../../components/breadcrumb";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { Base_URL } from "../../constants";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import clsx from "clsx";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function ApplyLeave(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    leavesData,
    holidays,
    user,
    setErrorMsg,
    errorMsg,
    alert,
    setAlert,
  } = props;

  const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;
  let [employeesDetails, setEmployeesDetails] = useState([]);
  async function getEmployeesDetails() {
    try {
      let { data } = await axios.get(`/users/view`);
      const tempData=data.filter((tempUser)=>{
        return tempUser.employeeCode !== user?.employeeCode
      })
      setEmployeesDetails(tempData);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(function () {
    getEmployeesDetails();
  }, []);
  function getDateDiff(date1, date2) {
    let days =
      moment(date1).startOf("day").diff(moment(date2).startOf("day"), "days") +
      1;
    return days;
  }

  let availableCOF = leavesData.availableLeaves.filter(
    (leave) => leave.title === "COF" && leave.balance > 0
  )[0];

  availableCOF =
    availableCOF === undefined
      ? ""
      : availableCOF.againstDates.map((date, idx) => (
        <option value={date} key={idx}>
          {moment(date).format("DD-MMM-YYYY")} ({moment(date).format("dddd")})
        </option>
      ));

  let availableRH = holidays
    .filter((holiday) => holiday.rhStatus === true)
    .map((holiday) => {
      return (
        <option value={holiday.holidayDate} key={holiday._id}>
          {moment(holiday.holidayDate).format("DD-MMM-YYYY")} (
          {moment(holiday.holidayDate).format("dddd")})
        </option>
      );
    });

  let leaveTypeOptions = leavesData.availableLeaves.map((leave, idx) => {
    return (
      <option
        value={leave.title}
        key={idx}
        data-leave-count={leave.balance}
      // disabled={leave.balance === 0}
      >
        {leave.title}{" "}
        {leave.balance >= 0 && leave.balance !== "" ? `(${leave.balance})` : ""}
      </option>
    );
  });

  function disableDates(date) {
    if (date.getDay() === 0 || date.getDay() === 6) return true;

    let holidayList = holidays.reduce(
      (obj, item) => Object.assign(obj, { [item.holidayDate]: item }),
      {}
    );

    if (values.leaveType === "RH") {
      return !(
        holidayList[date.toISOString()] &&
        date.toISOString() === holidayList[date.toISOString()].holidayDate &&
        holidayList[date.toISOString()].rhStatus
      );
    }
    return (
      holidayList[date.toISOString()] &&
      date.toISOString() === holidayList[date.toISOString()].holidayDate
    );
  }
  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>Leave</h1>

      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "Leave", link: "/leave/dashboard" },
            { heading: "Apply", link: "/leave/apply" },
          ]}
        />
      </div>
      <div className="tabContainer p24">
        <Grid container spacing={4}>
          <Grid item xs={8} className="xs-pl15 pT15 pB15 mB15">
            <Grid item xs={12} className="formContainer box-border mB15">
              <div className="input-group d-inline-block cMargin">
                <label className="d-inline-block font14">Employee ID : </label>
                <input
                  type="text"
                  placeholder=""
                  name="employeeCode"
                  disabled
                  value={values.employeeCode}
                  className="mL10 empID font15 empLabel"
                />
              </div>
              <div className="input-group d-inline-block mL20 cMargin">
                <label className="d-inline-block font14">Name : </label>
                <input
                  type="text"
                  placeholder=""
                  name="name"
                  disabled
                  value={values.name}
                  className="mL10 empID font15 empLabel"
                />
              </div>
            </Grid>
            <Grid container className="pT15 pB15 box-border mB15" spacing={0}>
              <Grid item xs={12} className="">
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    lg={4}
                    xl={4}
                    className="formContainer"
                  >
                    <div className="input-group">
                      <label>Leave Type <span className="error-text">*</span></label>
                      <select
                        name="leaveType"
                        onChange={(e) => {
                          if (
                            e.target[e.target.selectedIndex].getAttribute(
                              "data-leave-count"
                            ) === "0"
                          ) {
                            setErrorMsg(
                              "Leave balance must be greater than zero!"
                            );
                          } else {
                            setErrorMsg("");
                          }
                          setFieldValue("leaveType", e.target.value);
                          setFieldValue('days', 1)
                        }}
                        value={values.leaveType}
                        className={clsx(
                          "form-select",
                          getIn(errors, "leaveType") &&
                          getIn(touched, "leaveType") &&
                          "error"
                        )}
                      >
                        {leaveTypeOptions}
                      </select>
                      {errorMsg !== "" && (
                        <p className="error-text">{errorMsg}</p>
                      )}
                      {errors.leaveType && (
                        // <p className="error-text">{errors.leaveType}</p>
                        ''
                      )}
                    </div>
                  </Grid>
                  {values.leaveType === "COF" && (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      xl={4}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Available Dates</label>
                        <select
                          name="cofDate"
                          value={values.cofDate}
                          onChange={handleChange}
                          className={clsx(
                            "form-select",
                            getIn(errors, "cofDate") &&
                            getIn(touched, "cofDate") &&
                            "error"
                          )}
                          disabled={values.leaveType !== "COF"}
                        >
                          {availableCOF}
                        </select>
                        {errors.cofDate && (
                          <p className="error-text">{errors.cofDate}</p>
                        )}
                      </div>
                    </Grid>
                  )}
                  {values.leaveType === "RH" && (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      xl={4}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Available Dates</label>
                        <select
                          name="rhDate"
                          value={values.rhDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={clsx(
                            "form-select",
                            getIn(errors, "rhDate") &&
                            getIn(touched, "rhDate") &&
                            "error"
                          )}
                          disabled={values.leaveType !== "RH"}
                        >
                          {availableRH}
                        </select>
                        {errors.rhDate && (
                          <p className="error-text">{errors.rhDate}</p>
                        )}
                      </div>
                    </Grid>
                  )}
                  {(values.leaveType === "EL" ||
                    values.leaveType === "OD" ||
                    values.leaveType === "LWP" ||
                    values.leaveType === "PL"
                    ) && (
                      <Grid
                        item
                        xs={6}
                        md={4}
                        lg={4}
                        xl={4}
                        className="formContainer"
                      >
                        <div className="input-group">
                          <label>Time Period  <span className="error-text">*</span></label>
                          <select
                            name="dayType"
                            value={values.dayType}
                            onChange={(e) => {
                              if (e.target.value !== "1") {
                                setFieldValue("dateTo", values.dateFrom);
                                setFieldValue("days", 0.5);
                              } else {
                                setFieldValue(
                                  "days",
                                  getDateDiff(values.dateTo, values.dateFrom)
                                );
                              }
                              setFieldValue("dayType", e.target.value);
                            }}
                            className="form-select"
                            disabled={
                              !(
                                values.leaveType === "EL" ||
                                values.leaveType === "OD" ||
                                values.leaveType === "LWP" ||
                                values.leaveType === "PL"
                              )
                            }
                          >
                            <option value="1">Full Day</option>
                            <option value="2">First Half</option>
                            <option value="3">Second Half</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  {values.leaveType === "OD" && (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      xl={4}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Client </label>
                        <input
                          type="text"
                          name="client"
                          className={clsx(
                            "form-input",
                            getIn(errors, "client") &&
                            getIn(touched, "client") &&
                            "error"
                          )}
                          value={values.client}
                          onChange={handleChange}
                          disabled={values.leaveType !== "OD"}
                        />
                        {errors.client && (
                          <p className="error-text">{errors.client}</p>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} className="">
                <Grid container>
                  <>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      xl={4}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>From </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-dialog"
                            placeholder="Select date"
                            label={false}
                            className="picker-input minDate-error"
                            name="dateFrom"
                            value={values.dateFrom}
                            shouldDisableDate={disableDates}
                            onChange={(e) => {
                              if (values.dayType !== "1") {
                                setFieldValue("days", 0.5);
                              } else {
                                setFieldValue(
                                  "days",
                                  getDateDiff(
                                    values.dateTo,
                                    moment(e).startOf("day").toISOString()
                                  )
                                );
                              }
                              setFieldValue(
                                "dateFrom",
                                moment(e).startOf("day").toISOString()
                              );
                              if (values.dayType !== "1") {
                                setFieldValue(
                                  "dateTo",
                                  moment(e).startOf("day").toISOString()
                                );
                              }
                            }}
                            disabled={
                              values.leaveType === "RH" ||
                              values.leaveType === "COF"
                            }
                            autoOk={true}
                          />
                        </MuiPickersUtilsProvider>
                        {touched.dateFrom ||
                          (errors.dateFrom && (
                            <p className="error-text">{errors.dateFrom}</p>
                          ))}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      xl={4}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>To </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-dialog"
                            placeholder="Select date"
                            label={false}
                            className="picker-input minDate-error"
                            name="dateTo"
                            minDate={values.dateFrom}
                            value={values.dateTo}
                            disabled={
                              values.dayType !== "1" ||
                              values.leaveType === "RH" ||
                              values.leaveType === "COF"
                            }
                            shouldDisableDate={disableDates}
                            // onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue(
                                "dateTo",
                                moment(e).startOf("day").toISOString()
                              );
                              if (values.dayType !== "1") {
                                setFieldValue("days", 0.5);
                              } else {
                                setFieldValue(
                                  "days",
                                  getDateDiff(
                                    moment(e).startOf("day").toISOString(),
                                    values.dateFrom
                                  )
                                );
                              }
                            }}
                            autoOk={true}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      xl={4}
                      className="formContainer"
                    >
                      <div className="input-group">
                        <label>Days </label>
                        <input
                          type="text"
                          name="days"
                          className="form-input"
                          value={values.days}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                    </Grid>
                  </>
                </Grid>
                <Grid item xs={12} className="">
                  <Grid
                    item
                    xs={6}
                    md={12}
                    lg={12}
                    xl={12}
                    className="formContainer"
                  >
                    <div className="input-group">
                      <label>Reason <span className="error-text">*</span></label>
                      <textarea
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={clsx(
                          "formArea",
                          getIn(errors, "reason") &&
                          getIn(touched, "reason") &&
                          "error"
                        )}
                      ></textarea>
                      
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="">
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="formContainer"
                  >
                    <div className="input-group">
                      <label id="ccTo">CC to</label>
                      {/* {JSON.stringify(employeesDetails)} */}
                      {Object.keys(employeesDetails)?.length > 0 && (

                        <Autocomplete
                          multiple
                          className="autoLeave"
                          value={Array.isArray(values?.tempcc) ? values.tempcc: []}
                          options={employeesDetails}
                          getOptionLabel={(emp) => emp?.employeeName}
                          onChange={(event, employes) => {
                            const tempEmployee = []
                            employes.map((employee) => {
                              tempEmployee.push(employee?.employeeCode)
                            })
                            setFieldValue('cc', tempEmployee)
                            setFieldValue('tempcc', employes)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />

                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className="formContainer"
            >
              <div className="permissionBtn pL0">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className="mL20 routerLink-btn cancelButton"
                  component={RouterLink}
                  to="/leave/dashboard"
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={4} className="xs-pl15 pT15 pB15 mB15">
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  component="h2"
                  className={`${classes.title} mB15`}
                  color="textSecondary"
                  gutterBottom
                >
                  Approval Required From
                </Typography>
                {leavesData.approvalRequiredFrom.map((approver) => (
                  <Card className={classes.root} key={approver._id}>
                    <CardHeader
                      avatar={
                        <Avatar
                          src={`${Base_URL}${approver.avatar}`}
                          alt={approver.name}
                          aria-label="recipe"
                          className={classes.avatar}
                        ></Avatar>
                      }
                      title={approver.name}
                      subheader={approver.email}
                    />
                  </Card>
                ))}
              </CardContent>
            </Card>
            {/* <Card className={classes.root}>
              <CardContent>
                <Typography
                  component="h2"
                  className={`${classes.title} mB15`}
                  color="textSecondary"
                  gutterBottom
                >
                  Application Recipients
                </Typography>
                {leavesData.hrInCc.map(recipient => (
                  <Card className={classes.root} key={recipient._id}>
                    <CardHeader
                      avatar={
                        <Avatar
                          src={`${Base_URL}${recipient.avatar}`}
                          alt={recipient.name}
                          aria-label="recipe"
                          className={classes.avatar}
                        >
                          {recipient.name.charAt(0)}
                        </Avatar>
                      }
                      title={recipient.name}
                      subheader={recipient.email}
                    />
                  </Card>
                ))}
              </CardContent>
            </Card> */}
          </Grid>
        </Grid>
      </div>

      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={(e) => {
          setAlert({
            ...alert,
            open: false,
            class: "",
            message: "",
          });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        message={alert.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(e) => {
                setAlert({
                  ...alert,
                  open: false,
                  class: "",
                  message: "",
                });
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>
    </div>
  );
}

const withDataHOC = function (BaseComponent) {
  return function (props) {
    let [leavesData, setLeavesData] = useState({
      approvalRequiredFrom: [],
      hrInCc: [],
      availableLeaves: [],
    });

    async function getLeaveData() {
      try {
        let { data } = await axios.get(
          `/api/leaves/apply/info/employee/${props.user.employeeCode}`
        );
        setLeavesData(data.data);
      } catch (err) {
        console.log(err);
      }
    }

    let [holidays, setHolidays] = useState([]);


    async function getHolidays() {
      try {
        let { data } = await axios.get("/api/holidays");
        setHolidays(data.data);
      } catch (err) {
        console.log(err);
      }
    }

    let [errorMsg, setErrorMsg] = useState("");

    let [alert, setAlert] = useState({
      open: false,
      message: "",
      duration: 3000,
      color: "",
    });

    useEffect(function () {
      getLeaveData();
      getHolidays();
    }, []);

    function formReset() {
      props.resetForm();
    }

    return (
      <BaseComponent
        {...props}
        leavesData={leavesData}
        holidays={holidays}
        setErrorMsg={setErrorMsg}
        errorMsg={errorMsg}
        alert={alert}
        user={props.user}
        setAlert={setAlert}
        formReset={formReset}
        getLeaveData={getLeaveData}
      />
    );
  };
};

const formikApplyLeaveForm = withFormik({
  mapPropsToValues: ({ user, leavesData }) => {
    let intialLeave =
      leavesData.availableLeaves[0] &&
        leavesData.availableLeaves[0].balance !== 0
        ? leavesData.availableLeaves[0].title
        : "";
    let initialValues = {
      employeeCode: user.employeeCode,
      name: user.name,
      leaveType: intialLeave,
      cofDate: "",
      rhDate: "",
      dateFrom: moment().startOf("day").toISOString(),
      dateTo: moment().startOf("day").toISOString(),
      days: 1,
      dayType: "1",
      client: "",
      reason: "",
      cc: [],
      tempcc: []
    };

    return initialValues;
  },
  enableReinitialize: true,
  validationSchema: applyLeaveValidationSchema,

  handleSubmit: (values, formikBag) => {
    // console.log(formikBag);
    if (formikBag.props.errorMsg === "") {
      axios
        .post("api/leaves/apply/save", { ...values })
        .then(function (res) {
          formikBag.props.setAlert({
            ...formikBag.props.alert,
            message: res.data.message,
            color: "success",
            open: true,
          });
          // resetForm()
          formikBag.resetForm();
          formikBag.props.getLeaveData();
        })
        .catch(function (err) {
          console.log(err);
          formikBag.props.setAlert({
            ...formikBag.props.alert,
            message: err.data.message,
            color: "error",
            open: true,
          });
        });
    }
  },

  displayName: "ApplyLeave",
})(ApplyLeave);

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const ApplyLeaveForm = withDataHOC(formikApplyLeaveForm);

export default connect(mapStateToProps, null)(ApplyLeaveForm);
