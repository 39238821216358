import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { setAuthToken } from "./utils/tokenHelpers";
import jwtDecode from "jwt-decode";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import axios from "axios";
import { createMuiTheme } from "@material-ui/core/styles";
import { Base_URL } from "./constants/index.js";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_AUTH } from "./constants/index";

// import {indigo} from '@material-ui/core/colors';
axios.defaults.baseURL = Base_URL;
const theme = createMuiTheme({
  palette: {
    // primary: indigo,
  }
});

if (localStorage._token) {
  let token = localStorage._token;

  let decodedToken = jwtDecode(token);

  // check if token is not expired, if so logout the current user
  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    store.dispatch(logoutUser());
    // also clear user from redux store
    window.location.href = "/login";
  } else {
    let { exp, iat, jti, ...user } = decodedToken;
    setAuthToken(token);
    store.dispatch(setCurrentUser(user));
  }
}

render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={GOOGLE_AUTH.CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>;

      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
