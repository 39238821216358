import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import LinkIcon from "@material-ui/icons/Link";
import Tooltip from '@material-ui/core/Tooltip';

function ListItemLink(props) {
  const { icon, primary, to, tooltipTitle } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to]
  );

  return (
    <li>
      <Tooltip title={tooltipTitle}>
        <ListItem button component={renderLink}>
          {icon ? (
            <ListItemIcon>{icon}</ListItemIcon>
          ) : (
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={primary} />
        </ListItem>
      </Tooltip>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default ListItemLink;
