import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Axios from "axios";
import CustomDateFormat from "../../components/DateFormat";
import TableDateFilter from "../../components/TableDateFilter";
import moment from "moment";
import CustomBreadcrumb from "../../components/breadcrumb";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const columns = [
  { id: "date", label: "Date", minWidth: 100 },
  { id: "totalDeduct", label: "Total Extension / Deduction", minWidth: 170 },
  { id: "reason", label: "Reason", minWidth: 250 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

let Deduction = (props) => {
  const classes = useStyles();
  let [deductionData, setDeductionData] = useState([]);
  const [leaveInfo, setLeaveInfo] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  // current date
  const current = new Date();
  const endDate = moment(current).format("YYYY-MM-DD");
  const arr1 = endDate.split("-");
  const startDate = arr1[0] + "-" + arr1[1] + "-" + "01";

  let [startDateData, setStartDateData] = useState();
  let [endDateData, setEndDateData] = useState();
  // console.log(startDateData);
  async function getDeductionData(startDateData, endDateData, tempPage) {
    try {
      let { data } = await Axios.get(
        `/api/leaves/leavededuction?startDate=${startDateData}&endDate=${endDateData}&page=${tempPage}`
      );
      let tableData = [];
      tableData = data?.data.map((data) => {
        return {
          date: CustomDateFormat({ utc_date: data?.date, isDay: false }),
          totalDeduct: data?.totalDeduction,
          reason: data?.message,
          addition: data?.addition,
        }
      });
      setDeductionData(tableData);
      setTotalElements(data?.totalRecords);
    } catch (err) {
      console.log(err);
    }
  }
  // leave Info
  async function getLeaveInfo() {
    try {
      let { data } = await Axios.post("/api/leaves/leaveledger");
      setLeaveInfo(data?.data[0]);
    } catch (err) {
      console.log(err);
    }

  }

  const handleChangePage = (event, newPage) => {
    getDeductionData(startDateData, endDateData, newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(function () {
    getDeductionData(startDateData, endDateData, page);
    getLeaveInfo();
  }, []);

  let tableData = [];
  tableData = deductionData.map((data) => ({
    date: CustomDateFormat({ utc_date: data?.date, isDay: false }),
    totalDeduct: data?.totalDeduction,
    reason: data?.message,
    addition: data?.addition,
  }));

  function handleDeductionFilterSubmit(params) {
    let filterParams = params;
    let startDate = moment(filterParams?.startDate).format("YYYY-MM-DD");
    let endDate = moment(filterParams?.endDate).format("YYYY-MM-DD");
    setStartDateData(startDate);
    setEndDateData(endDate);
    setPage(0);
    getDeductionData(startDate, endDate, 0);
  }

  return (
    <div style={{ maxWidth: "100%" }} className="tableTitle">
      <h1>Planned Leave</h1>

      <div className="oflowH mB15">
        <CustomBreadcrumb
          data={[
            { heading: "Dashboard", link: "/" },
            { heading: "Leave", link: "/leave/dashboard" },
            { heading: "Planned Leave", link: "/deduction" },
          ]}
        />
      </div>
      <div className="tabContainer empFlexDiv">
        <div className="empFlex">
          {Object.keys(leaveInfo).length > 0 &&
            <>
              <Typography component="p">
                Emp. Code : <span>{leaveInfo?.employeeCode}</span>
              </Typography>
              <Typography component="h6">
                |
              </Typography>
              <Typography component="p">
                Emp. Name : <span>{leaveInfo?.user?.name}</span>
              </Typography>
            </>
          }
        </div>
        <div className="empFlex">
          {Object.keys(leaveInfo).length > 0 &&
            <>
              <Typography component="p">
                Total Leaves : <span>{leaveInfo?.PL?.total}</span>
              </Typography>
              <Typography component="h6">
                |
              </Typography>
              <Typography component="p">
                Leave Taken : <span>{leaveInfo?.PL?.taken}</span>
              </Typography>
              <Typography component="h6">
                |
              </Typography>
              <Typography component="p">
                Balance : <span>{leaveInfo?.PL?.balance}</span>
              </Typography>
            </>
          }

        </div>
      </div>
      <div className="tabContainer leaveTab">
        <div className="materialFilter">
          <TableDateFilter handleFilterSubmit={handleDeductionFilterSubmit} />
          {/* <div className="searchSec">
            <input
              type="search"
              placeholder="Search"
              onChange={handleAttendanceFilterSearch}
            />
          </div> */}
        </div>

        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" className="matAttendance">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {deductionData
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            column.id === 'totalDeduct' ? 
                            <TableCell key={column.id} align={column.align} >
                              {value}
                              {(row['addition'] === true) ? 
                                <span className='successText mL10'>(leave extended)</span> : ''
                              }

                            </TableCell> :
                            <TableCell key={column.id} align={column.align} >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}

                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {deductionData.length === 0 && (
                  <TableCell colSpan={columns.length} align={"center"}>
                    No Data Found
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};
export default Deduction;
