import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const columns = [
  { id: "empCode", label: "Emp. Code", minWidth: 100 },
  { id: "empName", label: "Emp. Name", minWidth: 170 },
  { id: "totalLeave", label: "Total Leaves (PL)", minWidth: 100 },
  { id: "balanceLeave", label: "Leave Balance (PL)", minWidth: 100 },
  { id: "takenLeave", label: "Leaves Taken (PL)", minWidth: 100 },
  { id: "year", label: "Year", minWidth: 100 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const initialState = {
  "leaveLedgerId": "",
  "balance": 0,
  "comment": ""
}

export default function LeaveTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  let [leaveData, setLeaveData] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [comment, setComment] = React.useState("");
  const [error, setError] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");

  const [selectedData, setSelectedData] = React.useState(initialState);
  let [alert, setAlert] = React.useState({
    open: false,
    message: "",
    duration: 3000,
    color: "",
  });

  const alertMsg = (msg) => {
    setAlert({
      ...alert,
      message: msg,
      color: "success",
      open: true,
    });
  };


  const handleClose = () => {
    setSelectedData(initialState);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    getLeaveData(newPage, { recordSearch: searchValue });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(function () {
    getLeaveData(0);
  }, []);

  async function getLeaveData(tempPage, body = {}) {
    try {
      let { data } = await Axios.post(`/api/leaves/leaveledger?type=report&page=${tempPage}`, body);
      let tableData = [];
      tableData = data?.data.map((data) => ({
        empCode: data?.employeeCode,
        empName: data?.user?.name,
        totalLeave: data?.PL?.total,
        balanceLeave: data?.PL?.balance,
        takenLeave: data?.PL?.taken,
        year: data?.PL?.year,
        leaveID: data?.PL?._id,
      }));
      setLeaveData(tableData);
      setTotalElements(data?.totalRecords);
    } catch (err) {
      console.log(err);
    }
  }

  const updateLeaveByHR = async () => {
    if (selectedData?.comment === "") {
      setError("* Comment is required.");
      return false;
    }
    try {
      handleClose();
      let { data } = await Axios.post(`/api/leaves/update-leave-by-hr`, { ...selectedData, balance: parseFloat(selectedData?.balance) });
      // console.log(data.message);
      getLeaveData(page, { recordSearch: searchValue });
      alertMsg(data.message);

    } catch (err) {
      console.log(err);
      var msg = "Something went wrong.";
      alertMsg(msg);
    }
  }
  function handleLeaveFilterSearch(e) {
    setSearchValue(e?.target?.value)
    getLeaveData(0, { recordSearch: e?.target?.value });
  }

  const handleClick = (row) => {
    setAnchorEl(true);
    setSelectedData((previousState) => {
      return { ...previousState, balance: row?.balanceLeave, leaveLedgerId: row?.leaveID }
    })
  }

  return (
    <>
      <div className="materialFilterCustom">
        <div className="searchSec">
          <input
            type="search"
            placeholder="Search"
            onChange={handleLeaveFilterSearch}
          />
        </div>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="matAttendance"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveData.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (

                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                          {(column.id === "balanceLeave") && (
                            <Button
                              color="primary"
                              onClick={() => handleClick(row)}
                              className="punchIcon"
                            >
                              <EditIcon />
                            </Button>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {leaveData.length === 0 && (
                <TableCell colSpan={columns.length} align={"center"}>
                  No Data Found !
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <div className="punchTime p-0">
              <form noValidate autoComplete="off">
                <div className="punchCard mB25">
                  <div className="mB25 cInput">
                    <TextField
                      id="balance"
                      label="Balance"
                      type="number"
                      variant="outlined"
                      className="w-100"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={selectedData?.balance}
                      onChange={(e) => {
                        // console.log(e?.target?.value)
                        setSelectedData((previousState) => {
                          return { ...previousState, balance: e?.target?.value }
                        })
                      }}
                    />
                  </div>
                  <TextField
                    id="outlined-secondary"
                    label="Comment"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={selectedData?.comment}
                    onChange={(e) => {
                      setSelectedData((previousState) => {
                        return { ...previousState, comment: e?.target?.value }
                      })
                    }}
                  />
                  <span className="error-text">{error}</span>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updateLeaveByHR}
                >
                  Submit
                </Button>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalElements}
          // count='30'
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={(e) => {
          setAlert({
            ...alert,
            open: false,
            class: "",
            message: "",
          });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        message={alert.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(e) => {
                setAlert({
                  ...alert,
                  open: false,
                  class: "",
                  message: "",
                });
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>
    </>
  );
}
