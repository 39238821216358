import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import CustomDateFormat from "../../components/DateFormat";
import { useRef } from "react";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

let Calender = props => {

    let [attendanceData, setAttendanceData] = useState([]);
    const [calenderData, setCalenderData] = useState([]);
    const [calenderMonth, setCalenderMonth] = useState({
        startDateValue: '',
        endDateValue: ''
    });

    async function getAttendanceData() {
        const values = {
            scope: "my",
            startDate: calenderMonth?.startDateValue,
            endDate: calenderMonth?.endDateValue,
            month: "month",
        };
        

        try {
            let { data } = await Axios.post(`/api/leaves/all/timedata`, values);
            setAttendanceData(data.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(function () {
        if(calenderMonth?.startDateValue!=='' && calenderMonth?.endDateValue!==''){
            getAttendanceData();
        }
    }, [calenderMonth]);

    useEffect(() => {
        let color_code = {
            'A': '#F5C9C7',
            'L': '#D5C3E6',
            'P': '#D9E99C',
            'WO': '#f5f5f5',
            'HLD': '#F2DDBE',
            null: '#fff'
        }
        const final_cal_data = attendanceData?.map(attend => {
            let customDate = CustomDateFormat({ utc_date: attend?.dateOffice, isDay: false })
            return { title: '', date: moment.utc(customDate).format("YYYY-MM-DD"), display: 'background', color: color_code[attend?.dayStatus] };
        })
        setCalenderData(final_cal_data)
    }, [attendanceData])

    const calendarRef = useRef()

    return (
        <>
            <div className="calenderLabel">
                <p><span className="aBox"></span> Absent</p>
                <p><span className="pBox"></span> Present</p>
                <p><span className="lBox"></span> Leave</p>
                <p><span className="wBox"></span> Week Off</p>
                <p><span className="hBox"></span> Holiday</p>
            </div>
            <div className="calenderContainer">
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    ref={calendarRef}
                    contentHeight={335}
                    customButtons={{
                        prevButton: {
                            text: <span className="calendarIcon"><ChevronLeftIcon /></span>,
                            click: function () {
                                const calendarAPI = calendarRef?.current?.getApi();
                                calendarAPI.prev()
                            },
                        },
                        nextButton: {
                            text: <span className="calendarIcon"><ChevronRightIcon /></span>,
                            click: function () {
                                const calendarAPI = calendarRef?.current?.getApi();
                                calendarAPI.next()
                            },
                        },
                    }}
                    initialView="dayGridMonth"
                    events={calenderData}
                    headerToolbar={{
                        // left: 'prev,myCustomButton',
                        left: 'prevButton',
                        center: 'title',
                        right: 'nextButton',
                    }}
                    datesSet={(dateInfo) => {
                        const startDateValue = moment.utc(dateInfo.start).local().format("YYYY-MM-DD")
                        const endDateValue = moment.utc(dateInfo.end).format("YYYY-MM-DD")
                        setCalenderMonth((pre) => {
                            return { ...pre, startDateValue: startDateValue, endDateValue: endDateValue }
                        })
                    }}
                />
            </div>
        </>
    );
};

export default Calender;
