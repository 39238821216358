import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import CustomDateFormat from "../../components/DateFormat";
import TableDateFilter from "../../components/TableDateFilter";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from '@material-ui/core/DialogContentText';
import DeleteIcon from '@material-ui/icons/Delete';

const columns = [
  { id: "empCode", label: "Emp. Code", minWidth: 100 },
  { id: "empName", label: "Emp. Name", minWidth: 170 },
  { id: "leaveType", label: "Leave Type", minWidth: 100 },
  { id: "from", label: "From", minWidth: 100 },
  { id: "to", label: "To", minWidth: 100 },
  { id: "leaveDay", label: "Leave Day", minWidth: 100 },
  { id: "approveStatus", label: "Approval Status", minWidth: 170 },
  { id: "reason", label: "Reason", minWidth: 200 },
  { id: "action", label: "", minWidth: 20 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const initialState = {
  "leaveLedgerId": ""
}

export default function Leave(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  let [leaveData, setLeaveData] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const [selectedData, setSelectedData] = React.useState(initialState);
  const [searchValue, setSearchValue] = React.useState("");
  const [filterParams, setFilterParams] = React.useState({
    "endDate": '',
    "startDate": '',
    "recordSearch": '',
    "scope": 'my'
  })
  const [error, setError] = React.useState("");
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    duration: 3000,
    color: ""
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
    setSelectedData((previousState) => {
      return { ...previousState, leaveLedgerId: row?.leaveId }
    })
    console.log(row?.leaveId)
  };

  const handleClose = () => {
    setSelectedData(initialState);
    setOpen(false);
  };

  const leavesDelete = () => {
    let leaveId = selectedData?.leaveLedgerId;
    console.log(selectedData)

    Axios.delete(`/api/leaves/delete/${leaveId}`).then(function (res) {
      setAlert({
        ...alert,
        message: res.data.message,
        color: "success",
        open: true
      });
      getLeaveData(page);
    });
    handleClose()
  }

  const handleChangePage = (event, newPage) => {
    getLeaveData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(function () {
    getLeaveData(page);
  }, [filterParams, searchValue]);

  async function getLeaveData(tempPage) {
    try {
      let { data } = await Axios.post(
        `/api/leaves/all/leaves?page=${tempPage}`,
        { ...filterParams, recordSearch: searchValue }
      );
      let tableData = [];
      tableData = data?.data.map((data) => ({
        empCode: data?.userProfile?.employeeCode,
        empName: data?.userProfile?.user?.name,
        leaveType: data?.leaveType?.title,
        from: `${CustomDateFormat({
          utc_date: data?.startDate,
          isDay: false,
        })} (${CustomDateFormat({ utc_date: data?.startDate, isDay: true })})`,
        to: `${CustomDateFormat({
          utc_date: data?.endDate,
          isDay: false,
        })} (${CustomDateFormat({ utc_date: data?.endDate, isDay: true })})`,
        leaveDay: data?.noOfDays,
        approveStatus: data?.status,
        reason: data?.reason,
        leaveId: data?._id,
      }));
      setLeaveData(tableData);
      setTotalElements(data?.totalRecords);
    } catch (err) {
      console.log(err);
    }
  }

  function handleAttendanceFilterSubmit(params) {
    setFilterParams((prev) => (
      { ...prev, ...params }
    ))
  }
  function handleAttendanceFilterSearch(e) {
    setSearchValue(e?.target?.value)
  }



  return (
    <>
      <div className="materialFilter">
        <TableDateFilter handleFilterSubmit={handleAttendanceFilterSubmit} />
        <div className="searchSec">
          <input
            type="search"
            placeholder="Search"
            onChange={handleAttendanceFilterSearch}
          />
        </div>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="matAttendance"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveData.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      // console.log(value);
                      return (
                        column.id === 'approveStatus' ?
                          <TableCell key={column.id} align={column.align}>
                            {column
                              ? <span className={(value === 'Rejected') ? 'error-text' : (value === 'Pending') ? 'pendingText' : 'successText'}>{value}</span>
                              : value}
                          </TableCell> :
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                            {((column.id === "action") && (row['approveStatus'] === 'Pending')) && (
                              <Button
                                color="primary"
                                onClick={() => { handleClickOpen(row) }}
                                className="punchIcon"
                              >
                                <DeleteIcon />
                              </Button>
                            )}
                          </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {leaveData.length === 0 && (
                <TableCell colSpan={columns.length} align={"center"}>
                  No Data Found !
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalElements}
          // count='30'
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the leave request ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => leavesDelete()} color="primary" className="rejectBtn" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
