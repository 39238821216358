exports.APP_TITTLE = "Neprophets OMS";

exports.TEMP_TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiJhY2U1dGFzNjdkYTc1YXM3NWFzODdzYSIsIm5hbWUiOiJTd2FwbmlsIFNodWtsYSIsInByb2ZpbGUiOiJEZXZlbG9wZXIiLCJkZXBhcnRtZW50IjoiRnVsbCBTdGFjayIsImp0aSI6ImE2ZjRlYzZjLTgwYjEtNDAxZS1hZmFhLTYyYWZmMTNhMzUzOSIsImlhdCI6MTU3MjUyNDgwNiwiZXhwIjoxNTcyNTI4NDA2fQ.yHUpE_JVT4gNfqaCZ3ToC4AoF1TNdhAsc2rXie2r5Sw";

//exports.Base_URL = "http://localhost:3300/";
//exports.Base_URL = "http://192.168.2.27:3300/";
//exports.Base_URL = "http://192.168.0.148:3600/";

//exports.Base_URL = "http://192.168.0.117:3600/";

exports.Base_URL = "https://staging.np-intranet.inroad.in/api/";

exports.GOOGLE_AUTH = {
  CLIENT_ID: "1063073720677-62hr75fa1ktiac5ocbukhf4d8l2vlcn2.apps.googleusercontent.com",
};
