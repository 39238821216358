import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import CustomDateFormat from "../../components/DateFormat";
import TableDateFilter from "../../components/TableDateFilter";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const columns = [
    { id: "empCode", label: "Emp. Code", minWidth: 50 },
    { id: "empName", label: "Emp. Name", minWidth: 170 },
    { id: "date", label: "Date", minWidth: 100 },
    { id: "day", label: "Day", minWidth: 100 },
    { id: "punchIn", label: "Punch In", minWidth: 100 },
    { id: "punchOut", label: "Punch Out", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 170 },
    { id: "hours", label: "Hours of Work", minWidth: 100 },
];

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
});
const initialState = {
    "timeRegister": ""
}

export default function AttendanceHistory(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    let [attendanceData, setAttendanceData] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState("");
    const [selectedData, setSelectedData] = React.useState(initialState);
    const [filterParams, setFilterParams] = React.useState({
        "endDate": '',
        "startDate": '',
        "recordSearch": '',
        "scope": 'team'
    })
    let [alert, setAlert] = React.useState({
        open: false,
        message: "",
        duration: 3000,
        color: "",
    });

    const alertMsg = (msg) => {
        setAlert({
            ...alert,
            message: msg,
            color: "success",
            open: true,
        });
    };
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (row) => {
        setOpen(true);
        setSelectedData((previousState) => {
            return { ...previousState, timeRegister: row?.id }
        })
        console.log(row?.id)
    };

    const handleClose = () => {
        setSelectedData(initialState);
        setOpen(false);
    };

    const cofApproval = () => {
        let timeRegisterId = selectedData?.timeRegister;
        console.log(selectedData)

        Axios.post(`/api/leaves/cof-approval?timeRegisterId=${timeRegisterId}`).then(function (res) {
            alertMsg(res.data.message);
            getAttendanceData(page);
        });
        handleClose()
    }

    const handleChangePage = (event, newPage) => {
        getAttendanceData(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    useEffect(function () {
        getAttendanceData(page);
    }, [filterParams, searchValue]);

    async function getAttendanceData(tempPage) {
        try {
            let { data } = await Axios.post(
                `/api/leaves/all/timedata?page=${tempPage}`,
                { ...filterParams, recordSearch: searchValue }
            );
            let tableData = [];
            tableData = data?.data.map((data) => {
                let pOut = 'NA'
                if (data?.out1 === 'missCurrent') {
                    pOut = 'n/a'
                } else {
                    pOut = CustomDateFormat({ utc_date: data?.out1, isDay: false, isTime: true, })
                }
                const hoursValue = data?.hourWorked ? (data?.hourWorked + "").split('.') : []
                let temp = 'NA'
                if (data?.hourWorked === 0) {
                    temp = 0
                }
                if (hoursValue.length) {
                    const minutes = (parseInt(hoursValue[1]) * 60) / Math.pow(10, (hoursValue[1] + "").length)
                    console.log(minutes);
                    if (!isNaN(parseInt(minutes))) {
                        temp = `${hoursValue[0]} hours ${parseInt(minutes)} minutes`
                    }
                    else {
                        temp = `${hoursValue[0]} hours`
                    }
                }
                return {
                    empCode: data?.userProfile?.employeeCode,
                    empName: data?.userProfile?.user?.name,
                    date: CustomDateFormat({ utc_date: data?.dateOffice, isDay: false }),
                    day: CustomDateFormat({ utc_date: data?.dateOffice, isDay: true }),
                    punchIn: CustomDateFormat({
                        utc_date: data?.in1,
                        isDay: false,
                        isTime: true,
                    }),
                    punchOut: pOut,
                    status: data?.dayStatus,
                    punchStatus: data?.punch_status,
                    hours: temp,
                    hourWorked: data?.hourWorked,
                    cofValue: data?.cofValue,
                    cofTaken: data?.cofTaken,
                    cofApproval: data?.cofApproval,
                    id: data?._id,
                }
            });
            setAttendanceData(tableData);
            setTotalElements(data?.totalRecords);
        } catch (err) {
            console.log(err);
        }
    }

    function handleAttendanceFilterSubmit(params) {
        setFilterParams((prev) => (
            { ...prev, ...params }
        ))
    }
    function handleAttendanceFilterSearch(e) {
        setSearchValue(e?.target?.value)
    }

    return (
        <>
            <div className="materialFilter">
                <TableDateFilter handleFilterSubmit={handleAttendanceFilterSubmit} />
                <div className="searchSec">
                    <input
                        type="search"
                        placeholder="Search"
                        onChange={handleAttendanceFilterSearch}
                    />
                </div>
            </div>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" className="matAttendance">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attendanceData
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    column.id === 'status' ?
                                                        <TableCell key={column.id} align={column.align} >
                                                            <span className={(value === 'A') ? 'error-text' : (value === 'P') ? 'successText' : ''}>{value}</span>
                                                            {((row['cofValue'] === true) && (row['cofTaken'] === false) && (row['cofApproval'] === false)) ?
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => { handleClickOpen(row) }}
                                                                    className="cofIcon"
                                                                >
                                                                    Mark COF
                                                                </Button>
                                                                : ''
                                                            }

                                                        </TableCell> :
                                                        (column.id === 'punchIn' || column.id === 'punchOut') ?
                                                            <TableCell key={column.id} align={column.align} >
                                                                {((row['punchStatus'] === 'miss') && (row['status'] === 'P') && value === 'NA') ?
                                                                    <span className={(value === 'NA') ? 'error-text' : ''}>{(value === 'NA') ? 'miss' : value}</span> : value
                                                                }


                                                            </TableCell> :
                                                            (column.id === 'hours') ?
                                                                <TableCell key={column.id} align={column.align} >
                                                                    {column
                                                                        ? <span className={((row['hourWorked'] < 8.5) && ((row['status'] === 'P'))) ? 'error-text' : ''}>{value}</span>
                                                                        : value}

                                                                </TableCell> :
                                                                <TableCell key={column.id} align={column.align} >
                                                                    {column.format && typeof value === "number"
                                                                        ? column.format(value)
                                                                        : value}

                                                                </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            {attendanceData.length === 0 && (
                                <TableCell colSpan={columns.length} align={"center"}>
                                    No Data Found
                                </TableCell>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to approve complimentary off ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => cofApproval()} color="primary" className="approve-btn" autoFocus>
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={alert.open}
                autoHideDuration={3000}
                onClose={(e) => {
                    setAlert({
                        ...alert,
                        open: false,
                        class: "",
                        message: "",
                    });
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                message={alert.message}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(e) => {
                                setAlert({
                                    ...alert,
                                    open: false,
                                    class: "",
                                    message: "",
                                });
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            ></Snackbar>
        </>
    );
}
