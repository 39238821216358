import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumb from "../../components/breadcrumb";
import {
    Grid,
    Paper,
    Button,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import CustomDateFormat from "../../components/DateFormat";
import TableDateFilter from "../../components/TableDateFilter";
import { Checkbox } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


const columns = [
    { id: "empCode", label: "Emp. Code", minWidth: 100 },
    { id: "empName", label: "Emp. Name", minWidth: 170 },
    { id: "leaveType", label: "Leave Type", minWidth: 80 },
    { id: "from", label: "From", minWidth: 100 },
    { id: "to", label: "To", minWidth: 100 },
    { id: "leaveDay", label: "Days Count", minWidth: 80 },
    { id: "reason", label: "Reason", minWidth: 250 },
    { id: "approveStatus", label: "Status", minWidth: 100 },
];

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
});

export default function LeaveHistory(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    let [leaveData, setLeaveData] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [status, setStatus] = React.useState('');
    let apiScope = "team";
    const [alert, setAlert] = React.useState({
        open: false,
        message: "",
        duration: 3000,
        color: ""
    });
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = []
            leaveData.map((n) => {
                if (n?.approveStatus === 'Pending') {
                    newSelecteds.push(n.leaveId)
                }
            });
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        getLeaveData(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const leavesAction = () => {
        const apriUrl = `api/leaves/action?actionType=${status.toLowerCase()}`;
        const postData = {
            leaveIds: selected
        };
        Axios.post(apriUrl, postData).then(function (res) {
            setAlert({
                ...alert,
                message: res.data.message,
                color: "success",
                open: true
            });
            getLeaveData(page);
            setSelected([])
        });
        handleClose()
    }

    const handleCheckedClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    useEffect(function () {
        getLeaveData(0);
    }, []);

    async function getLeaveData(tempPage, filterParams = {}) {
        if (props.isHistory === true) apiScope = "team";
        if (props.showAll === true) apiScope = "all";
        filterParams["scope"] = apiScope;

        try {
            let { data } = await Axios.post(
                `/api/leaves/all/leaves?page=${tempPage}`,
                filterParams
            );
            let tableData = [];
            tableData = data?.data.map((data) => ({
                empCode: data?.userProfile?.employeeCode,
                empName: data?.userProfile?.user?.name,
                leaveType: data?.leaveType?.title,
                from: `${CustomDateFormat({
                    utc_date: data?.startDate,
                    isDay: false,
                })} (${CustomDateFormat({ utc_date: data?.startDate, isDay: true })})`,
                to: `${CustomDateFormat({
                    utc_date: data?.endDate,
                    isDay: false,
                })} (${CustomDateFormat({ utc_date: data?.endDate, isDay: true })})`,
                leaveDay: data?.noOfDays,
                approveStatus: data?.status,
                reason: data?.reason,
                leaveId: data?._id,
            }));
            setLeaveData(tableData);
            setTotalElements(data?.totalRecords);
        } catch (err) {
            console.log(err);
        }
    }

    function handleAttendanceFilterSubmit(params) {
        let filterParams = { ...params };
        getLeaveData(0, filterParams);
    }
    function handleAttendanceFilterSearch(searchValue) {
        getLeaveData(0, { recordSearch: searchValue?.target?.value });
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <div className="tableTitle" style={{ maxWidth: "100%" }}>
            <h1>Leave Request</h1>
            <div className="oflowH mB15">
                <CustomBreadcrumb
                    data={[
                        { heading: "Dashboard", link: "/" },
                        { heading: "Leave", link: "/leave/dashboard" },
                        { heading: "Leave Status", link: "/leave/status" },
                    ]}
                />
            </div>
            <Grid container spacing={2} className="mB15">
                <Grid item xs={12}>
                    {/* {JSON.stringify(selected)} */}
                    <div className="rightTabs">
                        <Button
                            color="primary"
                            variant="contained"
                            className="approve-btn"
                            disabled={selected.length === 0}
                            onClick={() => {
                                setStatus('Approve')
                                handleClickOpen()
                            }}
                        >
                            APPROVE
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            className="reject-btn"
                            disabled={selected.length === 0}
                            onClick={() => {
                                setStatus('Reject')
                                handleClickOpen()
                            }}
                        >
                            REJECT
                        </Button>
                    </div>

                </Grid>
            </Grid>

            <div className="tabContainer">
                <div className="materialFilter">
                    <TableDateFilter handleFilterSubmit={handleAttendanceFilterSubmit} />
                    <div className="searchSec">
                        <input
                            type="search"
                            placeholder="Search"
                            onChange={handleAttendanceFilterSearch}
                        />
                    </div>
                </div>
                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            className="matAttendance"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selected.length > 0 && selected.length < leaveData.length}
                                            checked={leaveData.length > 0 && selected.length === leaveData.length}
                                            onChange={handleSelectAllClick}
                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                        />
                                    </TableCell>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leaveData.map((row, index) => {
                                    const isItemSelected = isSelected(row.leaveId);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                            key={row.leaveId}

                                        >
                                            <TableCell padding="checkbox">
                                                {row?.approveStatus === 'Pending' &&
                                                    <Checkbox
                                                        onClick={(event) => handleCheckedClick(event, row.leaveId)}
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}

                                                    />}

                                            </TableCell>

                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    column.id === 'approveStatus' ?
                                                        (<TableCell key={column.id} align={column.align}>
                                                            {column
                                                                ? <span className={(value === 'Rejected') ? 'error-text' : (value === 'Pending') ? 'pendingText' : 'successText'}>{value}</span>
                                                                : value}
                                                        </TableCell>) :
                                                        (<TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === "number"
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>)
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                {leaveData.length === 0 && (
                                    <TableCell colSpan={(columns.length) + 1} align={"center"}>
                                        No Data Found !
                                    </TableCell>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={totalElements}
                        // count='30'
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to {status.toLowerCase()} the leave request ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => leavesAction()} color="primary" className={(status === 'Approve' ? 'approve-btn' : 'rejectBtn')} autoFocus>
                        {status}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
