import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import CustomDateFormat from "../../components/DateFormat";
import TableDateFilter from "../../components/TableDateFilter";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const columns = [
  { id: "empCode", label: "Emp. Code", minWidth: 100 },
  { id: "empName", label: "Emp. Name", minWidth: 170 },
  { id: "leaveType", label: "Leave Type", minWidth: 100 },
  { id: "from", label: "From", minWidth: 100 },
  { id: "to", label: "To", minWidth: 100 },
  { id: "leaveDay", label: "Leave Day", minWidth: 100 },
  { id: "approveStatus", label: "Approval Status", minWidth: 170 },
  { id: "reason", label: "Reason", minWidth: 200 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function LeaveHistory(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  let [leaveData, setLeaveData] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState();
  const [comment, setComment] = React.useState("");
  const [selectedData, setSelectedData] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [filterParams, setFilterParams] = React.useState({
    "endDate": '',
    "startDate": '',
    "recordSearch": '',
    "scope": 'team'
  })
  const [error, setError] = React.useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleClick = (value, punchType) => {
    const tempPunchType = punchType === "punchIn" ? "in1" : "out1";
    setAnchorEl(true);
    setSelectedData({ ...value, punchType: tempPunchType });
    setSelectedDate(
      value?.[tempPunchType] !== "NA"
        ? new Date(moment(value?.[tempPunchType]).format())
        : new Date(moment().format())
    );
  };
  const handleClose = () => {
    setComment("");
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    getAttendanceData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(function () {
    getAttendanceData(page);
  }, [filterParams, searchValue]);

  async function getAttendanceData(tempPage) {
    try {
      let { data } = await Axios.post(
        `/api/leaves/all/leaves?page=${tempPage}`,
        { ...filterParams, recordSearch: searchValue }
      );
      let tableData = [];
      tableData = data?.data.map((data) => ({
        empCode: data?.userProfile?.employeeCode,
        empName: data?.userProfile?.user?.name,
        leaveType: data?.leaveType?.title,
        from: `${CustomDateFormat({
          utc_date: data?.startDate,
          isDay: false,
        })} (${CustomDateFormat({ utc_date: data?.startDate, isDay: true })})`,
        to: `${CustomDateFormat({
          utc_date: data?.endDate,
          isDay: false,
        })} (${CustomDateFormat({ utc_date: data?.endDate, isDay: true })})`,
        leaveDay: data?.noOfDays,
        approveStatus: data?.status,
        reason: data?.reason,
        leaveId: data?._id,
      }));
      setLeaveData(tableData);
      setTotalElements(data?.totalRecords);
    } catch (err) {
      console.log(err);
    }
  }

  function handleAttendanceFilterSubmit(params) {
    setFilterParams((prev) => (
      { ...prev, ...params }
    ))
  }
  function handleAttendanceFilterSearch(e) {
    setSearchValue(e?.target?.value)
  }

  const updatePunch = async (punchType) => {
    if (comment.trim() === "") {
      setError("* Comment is required.");
      return false;
    }
    let startDate = CustomDateFormat({
      utc_date: selectedData?.date,
      isDay: false,
    });
    let startDate1 = moment.utc(startDate).format("YYYY-MM-DD");
    let punch_time = moment.utc(selectedDate).local().format("h:mm A");
    try {
      handleClose();
      await Axios.get(
        `/crons/attendance/update-punch-record?employeeCode=${selectedData?.empCode}&startDate=${startDate1}&punchType=${selectedData?.punchType}&punchTime=${punch_time}&ip=103.245.10.227&comment=${comment}`
      );
      getAttendanceData(page);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="materialFilter">
        <TableDateFilter handleFilterSubmit={handleAttendanceFilterSubmit} />
        <div className="searchSec">
          <input
            type="search"
            placeholder="Search"
            onChange={handleAttendanceFilterSearch}
          />
        </div>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="matAttendance"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveData.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        column.id === 'approveStatus' ?
                          <TableCell key={column.id} align={column.align}>
                            {column
                              ? <span className={(value === 'Rejected') ? 'error-text' : (value === 'Pending') ? 'pendingText' : 'successText'}>{value}</span>
                              : value}
                            {/* {(column.id === "punchIn" ||
                            column.id === "punchOut") && (
                            <Button
                              color="primary"
                              onClick={() => handleClick(row, column.id)}
                              className="punchIcon"
                            >
                              <EditIcon />
                            </Button>
                          )} */}
                          </TableCell> :
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                            {/* {(column.id === "punchIn" ||
                            column.id === "punchOut") && (
                            <Button
                              color="primary"
                              onClick={() => handleClick(row, column.id)}
                              className="punchIcon"
                            >
                              <EditIcon />
                            </Button>
                          )} */}
                          </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {leaveData.length === 0 && (
                <TableCell colSpan={columns.length} align={"center"}>
                  No Data Found !
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <div className="punchTime p-0">
              <form noValidate autoComplete="off">
                <div className="punchCard mB25">
                  <div className="mB15">
                    <MuiPickersUtilsProvider className="" utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <TextField
                    id="outlined-secondary"
                    label="Comment"
                    variant="outlined"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                  <span className="error-text">{error}</span>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updatePunch}
                >
                  Submit
                </Button>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalElements}
          // count='30'
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
